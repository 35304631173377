.ant-tabs-nav .ant-tabs-tab-active {
  font-size: 16px;
  color: #dc3b05 !important;
}

.ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #dc3b05 !important;
  text-shadow: 0 0 0.25px #dc3b05;
}

.data {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
}

.orange {
  background-color: #dc3b05;
  color: #fff;
  cursor: pointer;
  margin-top: 20px !important;
  border-radius: 20px !important;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Additional styles for a cool and amazing design */
.signup-page {
  background-color: #fff;
  padding: 10px;
  /* margin-bottom: 20px; */
  /* border-radius: 8px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* width: 40%;
    margin: auto; */
}

/* h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  } */

.signup-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}

.orange:hover {
  background-color: #dc3b05;
}

/* Media query for responsive design */
@media screen and (max-width: 480px) {
  .signup-page {
    width: 90%;
    margin: 50px auto;
  }
}
/* @media screen and (max-width: 1000px) {
      .signup-page {
        width: 80%;
      
      }
    } */
.labeled-line {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
}

label {
  margin: 0 10px;
}
.blue {
  background-color: #2596be;
  color: #fff;
  cursor: pointer;
  margin-top: 10px !important;
  border-radius: 20px !important;
  padding: 10px 0 !important;
}
.blue:hover {
  background-color: #2596be;
}
.google-logo {
  width: 25px;
  height: 25px;

  background-color: #fff;
  border-radius: 50%;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #dc3b05 !important;
  /* border-radius: 50px !important; */
}
.content-container-auth {
  width: 50%;
  margin: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 120px;
  margin-bottom: 20px;
}
.css-19kzrtu {
  padding: 0 !important;
  padding-top: 20px !important;
}
.css-1vw5i9y-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-size: 20px !important;
}
.css-1aquho2-MuiTabs-indicator {
  height: 4px !important;
  background-color: #dc3b05 !important;
}
